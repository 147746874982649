<template>
  <div class="btn">
    <slot />
  </div>
</template>

<style scoped>
.btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: inherit;
  @apply py-2 px-4;
}
</style>
